import React from "react";
import styled from "styled-components";

import { SanityVillageHomeOption, Maybe, SanityVillage } from "@graphql-types";
import { GridContainer, Section, PageWidth, Container } from "@util/standard";
import { Link } from "@global";
import { mediaQuery, villageSubPagePaths } from "@util/constants";
import PropertyPreview from "./preview";

interface Props {
  data: Maybe<SanityVillageHomeOption>[];
  village: SanityVillage;
}

export default function OtherProperties({ data, village }: Props) {
  const slug = `${village.region?.slug?.current}/${village.slug?.current}`;
  const propertiesToShow = data.length > 2 ? data.slice(0, 1) : data;

  return (
    <Section>
      <PageWidth mobileFullWidth>
        <StyledContainer>
          <ContentContainer>
            <h3>Other home options.</h3>
          </ContentContainer>
          <Link
            linkText="View all home options"
            className="button-base"
            internalLink={villageSubPagePaths(slug).homeOptions}
          />
        </StyledContainer>
        {propertiesToShow.length > 0 && (
          <GridContainer repeat={2} tabletRepeat={1} rowGap={20} columnGap={20}>
            {propertiesToShow.map((listing, index) => {
              if (listing == null || index > 1) return null;

              return <PropertyPreview key={index} data={listing} />;
            })}
          </GridContainer>
        )}
      </PageWidth>
    </Section>
  );
}

const StyledContainer = styled(Container)`
  h3 {
    margin-bottom: 16px;
  }
  p {
    margin-top: 0px;
  }

  ${mediaQuery.tabletDown} {
    width: 80%;
    flex-direction: column;
    margin: auto;
    a {
      margin: 0 0 10% 0;
    }
  }
`;

const ContentContainer = styled(Container)`
  flex-direction: column;
  margin-bottom: 30px;
  width: 45%;

  ${mediaQuery.tabletDown} {
    flex-direction: column;
    margin: auto;
    align-items: initial;
    width: 100%;
  }
`;
