import { Blocks, ErrorMsg } from "@shared";
import { BlocksContent, Link, Video } from "@global";
import { Container, PageWidth } from "@util/standard";
import { Maybe, SanityContactPerson, SanityVillageHomeOption, SanityVillage } from "@graphql-types";
import { assets, colors, mediaQuery, villageSubPagePaths } from "@util/constants";

import Gallery from "@components/gallery";
import React from "react";
import styled from "styled-components";

interface Props {
  data: Maybe<SanityVillageHomeOption> | undefined;
  village: SanityVillage;
}

export default function PropertyPageContent({ data, village }: Props) {
  if (data == null) return <ErrorMsg data={data} msg="Error fetching property content" />;
  const {
    listingGallery,
    title,
    excerpt,
    excerptRichText,
    listingPrice,
    floorPlans,
    contacts,
    featuresLists,
    disclaimerText,
    disclaimerRichText,
    bedrooms,
    bathrooms,
    carparks,
    size,
    stage,
    status,
    brochure,
    heroVideo,
    blocks,
    unitNumber,
  } = data;
  const slug = `${village.region?.slug?.current}/${village.slug?.current}`;

  const Cta = () => {
    if (brochure) {
      return (
        <Link
          className="button-base"
          margin="30px 0 0 0"
          linkText="Download brochure"
          externalLink={brochure.asset?.url}
        />
      );
    }
    return (
      <Link
        className="button-base"
        margin="30px 0 0 0"
        linkText="Contact sales executive"
        internalLink={villageSubPagePaths(slug).contact}
      />
    );
  };

  return (
    <Wrapper>
      {heroVideo && <Video data={heroVideo} />}
      {listingGallery && <Gallery data={listingGallery} />}
      <PageWidth>
        <ContentSection aria-label="property-info" className="content-section">
          <div>
            <h1>{title}</h1>
            {excerptRichText && <BlocksContent data={excerptRichText} />}
            {!excerptRichText && <p>{excerpt}</p>}
            <Container columnGap="25px" isMobileColumn>
              <Card>
                {bedrooms && (
                  <Info>
                    <img src={assets.bed} />
                    <strong>{bedrooms}</strong>
                  </Info>
                )}
                {bathrooms && (
                  <Info>
                    <img src={assets.bath} />
                    <strong>{bathrooms}</strong>
                  </Info>
                )}
                {carparks && (
                  <Info>
                    <img src={assets.carpark} />
                    <strong>{carparks}</strong>
                  </Info>
                )}

                <Container flexDirection="column" margin="20px 0 0 0">
                  {unitNumber && (
                    <>
                      <strong>Unit Number</strong>
                      {unitNumber}
                    </>
                  )}
                  {size && (
                    <>
                      <strong>Size</strong>
                      {size}
                    </>
                  )}
                  {stage && (
                    <>
                      <strong>Stage</strong>
                      {stage}
                    </>
                  )}
                  {status && (
                    <>
                      <strong>Status</strong>
                      {status}
                    </>
                  )}
                </Container>
              </Card>
              <div>
                {featuresLists &&
                  featuresLists.map(featureList => {
                    if (featureList == null) return null;
                    const { title, listItems } = featureList;

                    return (
                      <div key={title}>
                        <p>
                          <strong>{title}</strong>
                        </p>
                        <ul>
                          {listItems?.map(item => {
                            if (item == null) return null;
                            return <li key={item}>{item}</li>;
                          })}
                        </ul>
                      </div>
                    );
                  })}
              </div>
            </Container>
          </div>
          <aside>
            <h2>{listingPrice}</h2>
            <Cta />
            {contacts && (
              <Container isMobileColumn flexDirection="column" rowGap="10px" margin="70px 0 0 0">
                {contacts.map(contact => (
                  <ContactCard contact={contact} />
                ))}
              </Container>
            )}
          </aside>
        </ContentSection>
        {disclaimerRichText && <BlocksContent data={disclaimerRichText} />}
        {!disclaimerRichText && disclaimerText && <Disclaimer>{disclaimerText}</Disclaimer>}
      </PageWidth>
      {floorPlans?.images && Boolean(floorPlans.images.length) && <Gallery data={floorPlans} />}
      {blocks && <Blocks data={blocks} />}
    </Wrapper>
  );
}

const ContactCard = ({ contact }: { contact: Maybe<SanityContactPerson> }) => {
  if (contact == null) return null;
  const { name, phone, email } = contact;

  return (
    <Card>
      <strong>{name}</strong>
      {phone && <a href={`tel:${phone}`}>{phone}</a>}
      {email && <a href={`mailto:${email}`}>{email}</a>}
    </Card>
  );
};

const Wrapper = styled.div`
  display: block;
  width: 100%;
  overflow: hidden;
  margin-top: 15vh;

  .content-section {
    margin-top: 50px;
  }
`;

const ContentSection = styled.section`
  display: flex;

  aside {
    display: inherit;
    flex-direction: column;
    margin-left: 100px;
    align-items: flex-end;
  }

  li {
    list-style: initial;
    margin-left: 20px;
  }

  ${mediaQuery.tabletDown} {
    flex-direction: column;

    aside {
      margin: 20px 0 0 0;
      align-items: flex-start;
    }
  }
`;

const Card = styled(Container)`
  flex-direction: column;
  background-color: ${colors.cream};
  height: fit-content;
  padding: 40px;
  min-width: 260px;

  ${mediaQuery.tabletDown} {
    width: 100%;
  }
`;

const Disclaimer = styled.p`
  font-size: 12px;
  margin-top: 40px;
`;

const Info = styled(Container)`
  column-gap: 12px;
  margin-bottom: 25px;
`;
