import React from "react";
import { graphql, PageProps } from "gatsby";

import { ErrorMsg, Header, SEO } from "@shared";
import { usePageMeta } from "@util/logicHooks";
import { Query } from "@graphql-types";
import { VillagePageContext } from "@state/types";
import PropertyPageContent from "@components/property/pageContent";
import OtherProperties from "@components/property/otherProperties";

interface Props extends PageProps {
  data: Query;
  pageContext: VillagePageContext;
}

export default function VillageHomeOptionInnerTemplate({
  data: { sanityVillage, sanityHeader },
  pageContext,
}: Props) {
  if (sanityVillage == null)
    return <ErrorMsg data={sanityVillage} msg="Error fetching data for page" />;

  const { _type, slug, villageColour, listings } = sanityVillage;

  const listingsToShow = listings;
  // hooks
  usePageMeta(villageColour, _type, pageContext);

  if (!listingsToShow) return null;
  const pageData = listingsToShow.find(listing => listing?.slug?.current === pageContext.pagePath);
  const otherProperties = listingsToShow.filter(
    listing => listing?.slug?.current !== pageContext.pagePath,
  );

  return (
    <div>
      <SEO seoData={pageData?.seo} slug={slug?.current as string} />
      <Header data={sanityHeader} village={sanityVillage} />
      <PropertyPageContent data={pageData} village={sanityVillage} />
      {Boolean(otherProperties.length) && (
        <OtherProperties data={otherProperties} village={sanityVillage} />
      )}
    </div>
  );
}

export const query = graphql`
  query villageHomeOptionInnerQuery($slug: String) {
    sanityHeader {
      ...sanityHeader
    }
    sanityVillage(slug: { current: { eq: $slug } }) {
      ...sanityVillageMeta
      listings {
        ...sanityVillageHomeOption
      }
    }
  }
`;
